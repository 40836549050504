const enUS = {
  // Layout.Menu
  '/orders': 'Orders',
  '/shipments': 'Shipments',
  '/settings': 'Settings',
  '/profile': 'Profile',
  '/account/profile': 'Account',
  '/stores': 'Stores',
  '/carriers': 'Carriers',
  '/addresses': 'Addresses',
  '/labels': 'Labels',
  '/packages': 'Packages',
  '/rate': '费用查询',
  // Order Page
  orders: 'Orders',
  order: 'order',
  shipped: 'shipped',
  customer: 'Customer',
  editRecipient: 'Edit Recipient',
  rates: 'Rates',
  download: 'Download',
  viewOrder: 'View Order',
  buy: 'Buy',
  buy_again: 'Buy Again',
  downloadPackSlip: 'Download Packing Slip',
  hideOrder: 'Hide Order',
  uploadCsv: 'Upload CSV',
  createLabel: 'Create Label',
  create_another_label: 'Create Another Label',
  createOrderManually: 'Create Order Manually',
  saveAndContinue: 'Save and Continue',
  sender: 'Sender',
  recipient: 'Recipient',
  from: 'From',
  to: 'To',
  orderDetails: 'Order Details',
  // Item
  items: 'Items',
  item: 'Item',
  deleteItem: 'Delete Item',
  no_item: 'No items in this order',
  add_item: 'Add item to order',
  add_package: 'Add Package',
  items_summary: 'In this shipment',
  quantity: 'Quantity',
  weight: 'Weight',
  value: 'Value',
  fulfilled_order_items: 'Fulfilled orders cannot be modified.',
  item_count: 'items',
  order_total: 'Order total',
  new_item: 'New Item',
  item_detail: 'Item Detail',
  item_description: 'Description',
  unit_weight: 'Unit Weight',
  unit_value: 'Unit Value',
  country_of_origin: 'Country of Origin',
  require_greater_zero: "Can't be 0",
  item_total: 'Total',
  // Package Info
  packageInfo: 'Package Info',
  custom_dimensions: 'Custom Dimensions',
  dimentions: 'Dimentions',
  dimentions_sub: 'L x W x H',
  package_weight: 'Package Weight',
  use_item_weight: 'Use weight of items',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  add_package_info: 'Add Package Info',
  // Shipment Options
  shipment_options: 'Shipment Options',
  shipment_date: 'Shipment Date',
  // Address Card
  return: 'Return',
  address_list: 'Addresses...',
  use_as_return: 'Use as return address',
  // Rate Card
  package_info_missing: 'Package info required',
  jump_to_error: 'Jump to error',
  shipment_date_wrong: 'Shipment Date cannot be an old date.',
  refresh: 'Refresh',
  rate_unavailable: 'Rate Unavailable',
  label_unavailable: 'Can not buy label',
  fix_following: 'Fix the following to get rate',
  cheapest: 'CHEAPEST',
  fastest: 'FASTEST',
  more_rates: 'MORE RATES',
  // Service Card
  service_title: 'Service',
  // Shipment Page
  shipments: 'Shipments',
  schedule_pickup: 'Schedule Pickup',
  create_manifest: 'Create Manifest',
  carrier: 'Carrier',
  service: 'Service',
  tag: 'Tag',
  shipment_rate: 'Rate',
  tracking_status: 'Tracking Status',
  label_status: 'Label Status',
  // Account Page
  accountTitle: 'Account',
  profile: 'Profile',
  billing: 'Billing',
  curPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  allTransactions: 'All Transactions',
  dateTime: 'Date, Time',
  type: 'Type',
  details: 'Details',
  trackingNumber: 'Tracking Number',
  status: 'Status',
  amount: 'Amount',
  Paid: 'Paid',
  Credited: 'Credited',
  'Label payment': 'Label payment',
  'Label refund': 'Label refund',
  'Add Fund': 'Add Fund',
  Deduction: 'Deduction',
  exportCsv: 'Export CSV',
  // Carriers Page
  carriers: 'Carriers',
  carriersSubtitle: 'Discounted ParcelsElite rates.',
  account: 'Account',
  usDomestic: 'U.S. Domestic',
  usInternational: 'U.S. International',
  cnImport: 'China Import',
  testMode: 'Test Mode',
  testRate: 'Test Rate',
  updateAccount: 'Update Account',
  enableTestMode: 'Enable Test Mode',
  testModeDescription:
    'Test mode lets you create sample labels to familiarize yourself with the process without spending money.',
  // Address Page
  addresses: 'Addresses',
  addressSubtitle:
    'Save addresses you use most often to speed up your label purchase process.',
  locations: 'Locations',
  newAddress: 'New Address',
  editAddess: 'Edit Address',
  deleteAddress: 'Delete Address',
  defaultSender: 'Default sender address',
  setDefaultSender: 'Set as Default sender address',
  // Label Page
  labels: 'Labels',
  labelsSubtitle: 'Configure your label formats and options',
  labelsSettingTitle: 'Default Label and Packing Slip Formats',
  labelFormat: 'Default label format',
  packFormat: 'Default Packing Slip format',
  // Package Page
  packages: 'Packages',
  packagesSubtitle:
    'Save packages you use most often to speed up your label purchase process.',
  weightUnitSelection: 'Default weight unit',
  distanceUnitSelection: 'Default distance unit',
  saveSettigns: 'Save',
  // Login Page
  wrong_email_pass: 'Email or password invalid',
  wrong_email_format: 'Not an email',
  enter_email: 'Please enter your email',
  enter_pass: 'Please enter password',
  forgot_pass: 'Forgot password',
  sign_up: 'Sign Up',
  sign_in: 'LOG IN',
  log_out: 'Log out',
  // Register Page
  confirm_pass: 'Confirm Password',
  use_strong_pass: 'Please use strong password',
  create_account: 'Create Account',
  already_has_account: 'Already have a ParcelsElite account',
  // Forgot Page
  send_email: 'Successfully sent an email to the provided email address',
  check_email_reset:
    'Please check you inbox and follow the instructions to reset your password.',
  go_back_login: 'Go Back to Login',
  trouble_login: 'Having trouble signing in',
  enter_email_reset: 'Enter your email address to reset password',
  go_back: 'Go back to ',
  reset_pass: 'Reset Password',
  reset_your_pass: 'Reset your password',
  enter_new_pass: 'Enter your new passwords',
  // Buy Modal
  no_fee_testing: 'No fee will be charged for test mode',
  insufficient_fund: 'Insufficient balance, please contact us to add fund',
  confirm_purchase: 'Confirm Purchase',
  purchase_label: 'Purchaase Label',
  aknowledge_terms: 'I have read and agree to the terms above',
  dhl_term:
    'DHL eCommerce requires a minimum shipment of $200 per day. If the total shipment is less than $200 on that day, then $200 will be charged instead.',
  label_prucahsed: 'label has been purchased',
  shipping_labels: 'Shipping Labels',
  download_doc: 'Download',
  packing_slip: 'Packing Slip',
  download_labels: 'Download Labels',
  download_forms: 'Download Invoice',
  // Common
  no_data: 'No Data',
  edit: 'Edit',
  name: 'Name',
  firstName: 'FirstName',
  lastName: 'LastName',
  company: 'Company',
  email: 'Email',
  phone: 'phone',
  country: 'Country',
  street1: 'Street1',
  street2: 'Street2',
  city: 'City',
  state: 'State',
  zip: 'Postal Code/Zip',
  cancel: 'Cancel',
  apply: 'Apply',
  ok: 'OK',
  searchSelect: 'Search to Select',
  requiredField: ' is a required field.',
  needPassword: 'We Need Your Current Password When Updating Your Profile',
  invalidFormat: ' format invalid',
  enabled: 'Enabled',
  disabled: 'Disabled',
  save: 'Save',
  weekPassword: 'Password is not strong enough',
  passNotMatch: 'Password dose not match',
  min8Chars: 'Minimum 8 Characters',
  upperLowerNum: 'Upper Case, Lower Case, Number',
  specialChars: 'Special Characters: -#!$%^&*()_+|~=`{}[]:";\'<>?,./',
  date: 'Date',
  copy: 'Copy',
  copied: 'Copied to Clipboard',
  wrong_format: 'Wrong Format',
  account_balance: 'Balance',
  account_deposit: 'Deposit'
};

export default enUS;
